<template>
  <BaseSelect2
    async
    class="base-select-first"
    clearable
    empty-placeholder="К сожалению, мы ничего не смогли найти. Уточните поисковой запрос."
    name="search"
    :options="computedOptions"
    placeholder="Поиск по базе знаний"
    searchable
    @search="(query) => onSearchHandler(query)"
  >
    <template #option="{ option }">
      <BaseLink
        class="typography__label1 search__option"
        :href="`/faq/articles/${option.value}`"
      >
        <span v-html="option.name" />

        <span
          class="typography__label2"
          v-html="option.category"
        />
      </BaseLink>
    </template>

    <template #prefix>
      <BaseIcon2
        id="icon-search"
        class="search__icon"
        height="24"
        width="24"
      />
    </template>
  </BaseSelect2>
</template>

<script lang="ts" setup>
const searchQuery = ref<null | string>(null);

const { list } = await useGetFaqArticleListApi({
  fetchOptions: computed(() => {
    return {
      params: {
        ...(searchQuery.value !== null && {
          name: searchQuery.value,
        }),
      },
    };
  }),
  options: {
    watch: [searchQuery],
  },
});

const computedOptions = computed(() => {
  return (
    list.value?.map((article) => {
      return {
        category: article.category.name,
        name: article.name,
        value: article.slug,
      };
    }) ?? []
  );
});

const onSearchHandler = useDebounceFn((query: string) => {
  searchQuery.value = query;
}, 500);
</script>

<style scoped>
.search__icon {
  color: var(--grey-3);
}

.search__option {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 8px 12px;
}

@media (--hover) {
  .search__option:hover {
    background-color: var(--grey-7);
  }
}
</style>
