const apiFetchRequest = async <T>(url: string, options?: ApiRestOptions): Promise<ApiRestResponse<T>> => {
  try {
    const response = await $fetch.raw<T>(url, options);

    return {
      // eslint-disable-next-line no-underscore-dangle
      result: (response as { _data?: T })._data ?? null,
      status: response.status,
      success: true,
    };
  } catch (error) {
    console.error(error);

    if (typeof error === "object" && error !== null) {
      const errorData = error as { data?: T; status?: number };

      if (errorData.data !== undefined) {
        return {
          result: errorData.data,
          status: errorData.status ?? 500,
          success: false,
        };
      }
    }

    return {
      result: null,
      status: 500,
      success: false,
    };
  }
};

export { apiFetchRequest };
