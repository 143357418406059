const useFetchRequest = async <T>(
  handler: (fetchOptions: ApiRestOptions) => Promise<ApiRestResponse<T>>,
  options?: AsyncDataOptions<ApiRestResponse<T>>,
) => {
  const cookie = useRequestHeader("cookie");

  const defaultFetchRequestParams: ApiRestOptions = {
    ...(cookie !== undefined && {
      headers: {
        Cookie: cookie,
      },
    }),
  };

  const id = useId();

  return useAsyncData<ApiRestResponse<T>>(
    id,
    async () => {
      return handler(defaultFetchRequestParams);
    },
    options,
  );
};

export { useFetchRequest };
