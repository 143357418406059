const useGetFaqArticleListApi = async ({
  fetchOptions,
  options,
}: {
  fetchOptions?: MaybeRef<Omit<ApiRestOptions, "params"> & { params: FaqArticleListQueryParams }>;
  options?: AsyncDataOptions<ApiRestResponse<ApiRestResult<FaqArticleListResponse>>>;
} = {}) => {
  const response = await useFetchRequest(async (defaultOptions) => {
    return getFaqArticleListApi(deepmerge(defaultOptions, unref(fetchOptions)));
  }, options);

  const list = computed(() => {
    return response.data.value?.result?.data?.list ?? null;
  });

  return {
    ...response,
    list,
  };
};

export { useGetFaqArticleListApi };
